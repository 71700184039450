import { action, makeObservable, observable, runInAction } from 'mobx';
import { client as mapUpdateRestApiClient } from '@api/mapupdate-client';
import { client as mapUpdateGraphqlClient } from '@api/mapupdate-graphql';
import { gql } from '@apollo/client';
import { setWith } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line no-unused-vars
import StateMachineStore from './stateMachine'; //NOSONAR
import { campaignKpiStatus } from '@components/mapupdate/rollouts/campaigns/Campaign.constants.jsx';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import i18n from 'i18next';

let instance; // singleton instance

const SEARCH_CAMPAIGNS_QUERY = gql`
    query GetCampaignsQuery(
        $limit: Int = 50
        $offset: Int = 0
        $query: String = "%"
        $orderBy: [mu_campaigns_order_by!] = {}
    ) {
        mu {
            campaigns(
                limit: $limit
                offset: $offset
                order_by: $orderBy
                where: {
                    _or: [
                        { map: { display_name: { _ilike: $query } } }
                        { id_text: { _ilike: $query } }
                        { campaign_name: { _ilike: $query } }
                    ]
                }
            ) {
                id
                id_text
                status
                campaign_name
                map {
                    display_name
                }
                created_at
            }
            campaigns_aggregate(
                where: {
                    _or: [
                        { map: { display_name: { _ilike: $query } } }
                        { id_text: { _ilike: $query } }
                        { campaign_name: { _ilike: $query } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

function buildQueryAndAggregate(region, limit) {
    return `
        ${region}: campaigns(
            limit: ${limit}
            order_by: $orderBy
            where: { 
              _and: [
                { map: { map_region_enum: { _eq: "${region}" }}},
                  {_or: [
                    { map: { display_name: { _ilike: $query } } },
                    { campaign_name: { _ilike: $query } }
                      ]
                } 
              ]
            }
        ) {
            id
            id_text
            status
            campaign_name
            map {
                display_name
            }
            created_at
            rollouts_aggregate {
                aggregate {
                    count
                }
            }
            rolloutsStarted_aggregate: rollouts_aggregate(where: {_or: [{rollout_state: {rollout_state_type: {_eq: "MapUpdateInitiatedState"}}}, {rollout_state: {rollout_state_type: {_eq: "MapUpdatesOfferedState"}}}, {rollout_state: {rollout_state_type: {_eq: "DownloadInitiatedState"}}}, {rollout_state: {rollout_state_type: {_eq: "DownloadInProgressState"}}}, {rollout_state: {rollout_state_type: {_eq: "VcmDownloadCompletedState"}}}, {rollout_state: {rollout_state_type: {_eq: "DownloadsCompleteState"}}}, {rollout_state: {rollout_state_type: {_eq: "MapUpdatesPatchingState"}}}, {rollout_state: {rollout_state_type: {_eq: "MapUpdatesPatchedState"}}}, {rollout_state: {rollout_state_type: {_eq: "AppUpdateInitiatedState"}}}]}) {
                aggregate {
                    count
                }
            }
            rolloutsQueued_aggregate: rollouts_aggregate(where: {rollout_state: {rollout_state_type: {_eq: "InitialRolloutState"}}}) {
                aggregate {
                    count
                }
            }
            rolloutsSucceeded_aggregate: rollouts_aggregate(where: {rollout_state: {rollout_state_type: {_eq: "RolloutDoneState"}}}) {
                aggregate {
                    count
                }
            }
            rolloutsFailed_aggregate: rollouts_aggregate(where: {rollout_state: {rollout_state_type: {_eq: "RolloutFailedRepeatedlyState"}}}) {
                aggregate {
                    count
                }
            }
            rolloutsWarning_aggregate: rollouts_aggregate(where: { _or: [{rollout_state: {rollout_state_type: {_eq: "RolloutFailedState"}}}, {rollout_state: {rollout_state_type: {_eq: "MapUpdatesRejectedState"}}}, {rollout_state: {rollout_state_type: {_eq: "SuspendedRolloutState"}}}]}) {        
                aggregate {
                    count
                }
            }
            rolloutsCancelled_aggregate: rollouts_aggregate(where: {rollout_state: {rollout_state_type: {_eq: "RolloutCanceledState"}}}) {
                aggregate {
                    count
                }
            }
        }
        ${region}_aggregate: campaigns_aggregate(
            where: { 
              _and: [
                { map: { map_region_enum: { _eq: "${region}" }}},
                  { _or: [
                    { map: { display_name: { _ilike: $query } } },
                    { campaign_name: { _ilike: $query } }
                  ]
              } 
              ]
            }
        ) {
            aggregate {
                count
            }
        }
    `;
}

function buildSearchCampaignsByRegion(regions) {
    return gql`
        query GetCampaignsQuery(
            $query: String = "%"
            $orderBy: [mu_campaigns_order_by!] = {}
        ) {
            mu{
                ${regions.map((region) => buildQueryAndAggregate(Object.keys(region), region[Object.keys(region)]))}
            }
        }
    `;
}

const GET_CAMPAIGN_DETAILS = gql`
    query GetCampaignsDetailsQuery($campaign_id: uuid = "") {
        mu {
            campaigns(where: { id: { _eq: $campaign_id } }) {
                id
                status
                campaign_name
                map {
                    map_region_enum
                    display_name
                    file {
                        filename
                        size_bytes
                    }
                    previous_version_number
                    version_number
                    approval_state
                }
                created_at
            }
            campaign_vehicles_from_group_filter(distinct_on: group_id, where: { campaign_id: { _eq: $campaign_id } }) {
                name
                vehicle_id
                vin
                vehicle_group {
                    group {
                        name
                        description
                    }
                }
            }
            rolloutsStarted_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _eq: $campaign_id } }
                        {
                            _or: [
                                { rollout_state: { rollout_state_type: { _eq: "MapUpdateInitiatedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "MapUpdatesOfferedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "DownloadInitiatedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "DownloadInProgressState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "VcmDownloadCompletedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "DownloadsCompleteState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "MapUpdatesPatchingState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "MapUpdatesPatchedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "AppUpdateInitiatedState" } } }
                            ]
                        }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            rolloutsQueued_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _eq: $campaign_id } }
                        { rollout_state: { rollout_state_type: { _eq: "InitialRolloutState" } } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            rolloutsSucceeded_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _eq: $campaign_id } }
                        { rollout_state: { rollout_state_type: { _eq: "RolloutDoneState" } } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            rolloutsFailed_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _eq: $campaign_id } }
                        { rollout_state: { rollout_state_type: { _eq: "RolloutFailedRepeatedlyState" } } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            rolloutsWarning_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _eq: $campaign_id } }
                        {
                            _or: [
                                { rollout_state: { rollout_state_type: { _eq: "RolloutFailedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "MapUpdatesRejectedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "SuspendedRolloutState" } } }
                            ]
                        }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            rolloutsCancelled_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _eq: $campaign_id } }
                        { rollout_state: { rollout_state_type: { _eq: "RolloutCanceledState" } } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            campaigns_updated_vehicles_aggregate(where: { campaign_id: { _eq: $campaign_id } }) {
                aggregate {
                    count
                }
            }
            rollouts_aggregate(where: { campaign_id: { _eq: $campaign_id } }) {
                aggregate {
                    count
                }
            }
            campaign_vehicles_from_group_filter_aggregate(
                distinct_on: group_id
                where: { campaign_id: { _eq: $campaign_id } }
            ) {
                aggregate {
                    count
                }
            }
            campaign_vehicles_from_vehicle_filter_aggregate(where: { campaign_id: { _eq: $campaign_id } }) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const GET_CAMPAIGN_METRICS = gql`
    query getCampaignMetrics {
        mu {
            campaignsCreated_aggregate: campaigns_aggregate(where: { status: { _ilike: "CREATED" } }) {
                aggregate {
                    count
                }
            }
            campaignsStarted_aggregate: campaigns_aggregate(where: { status: { _ilike: "STARTED" } }) {
                aggregate {
                    count
                }
            }
            campaignsStopped_aggregate: campaigns_aggregate(where: { status: { _ilike: "STOPPED" } }) {
                aggregate {
                    count
                }
            }
            rolloutsTotal_aggregate: rollouts_aggregate(where: { campaign_id: { _is_null: false } }) {
                aggregate {
                    count
                }
            }
            rolloutsStarted_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _is_null: false } }
                        {
                            _or: [
                                { rollout_state: { rollout_state_type: { _eq: "MapUpdateInitiatedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "MapUpdatesOfferedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "DownloadInitiatedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "DownloadInProgressState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "VcmDownloadCompletedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "DownloadsCompleteState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "MapUpdatesPatchingState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "MapUpdatesPatchedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "AppUpdateInitiatedState" } } }
                            ]
                        }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            rolloutsQueued_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _is_null: false } }
                        { rollout_state: { rollout_state_type: { _eq: "InitialRolloutState" } } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            rolloutsSucceeded_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _is_null: false } }
                        { rollout_state: { rollout_state_type: { _eq: "RolloutDoneState" } } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            rolloutsFailed_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _is_null: false } }
                        { rollout_state: { rollout_state_type: { _eq: "RolloutFailedRepeatedlyState" } } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            rolloutsWarning_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _is_null: false } }
                        {
                            _or: [
                                { rollout_state: { rollout_state_type: { _eq: "RolloutFailedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "MapUpdatesRejectedState" } } }
                                { rollout_state: { rollout_state_type: { _eq: "SuspendedRolloutState" } } }
                            ]
                        }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
            rolloutsCancelled_aggregate: rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _is_null: false } }
                        { rollout_state: { rollout_state_type: { _eq: "RolloutCanceledState" } } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const GET_CAMPAIGN_UPDATED_VEHICLES = gql`
    query GetCampaignUpdatedVehicles($campaignId: uuid = "") {
        mu {
            campaigns_updated_vehicles(where: { campaign_id: { _eq: $campaignId } }) {
                campaign_id
                status
                name
                vehicle_id
                vin
            }
        }
    }
`;

const GET_CAMPAIGN_ELIGIBLE_VEHICLES = gql`
    query GetCampaignEligibleVehicles($campaignId: uuid = "") {
        mu {
            campaigns_eligible_vehicles(where: { campaign_id: { _eq: $campaignId } }) {
                campaign_id
                name
                vehicle_id
                vin
            }
        }
    }
`;

const GET_CAMPAIGN_WHITELISTED_VEHICLES = gql`
    query GetCampaignWhitelistedVehicles($campaignId: uuid = "") {
        mu {
            campaigns_whitelisted_vehicles(where: { campaign_id: { _eq: $campaignId } }) {
                campaign_id
                vehicle_id
                vin
                name
            }
        }
    }
`;

const GET_CAMPAIGN_FROM_GROUPS_FILTER = gql`
    query GetCampaignWhitelistedVehicles($campaignId: uuid = "") {
        mu {
            campaign_vehicles_from_group_filter(where: { campaign_id: { _eq: $campaignId } }) {
                campaign_id
                name
                vehicle_id
                vehicle_group {
                    group {
                        name
                        description
                    }
                }
            }
        }
    }
`;

const GET_CAMPAIGN_ROLLOUTS = gql`
    query GetCampaignRollouts($campaignId: uuid = "") {
        mu {
            rollouts(
                order_by: [{ vehicle: { name: asc } }, { created_at: asc }]
                where: { campaign_id: { _eq: $campaignId } }
            ) {
                vehicle {
                    name
                    vin
                }
                rollout_state {
                    updated_at
                    status: payload(path: "['@type']")
                }
            }
        }
    }
`;

export class CampaignStore {
    campaignLoading = false;
    campaignList = [];
    campaignListTotal = 0;
    campaignRegionListTotal = [];
    currentCampaign = {};
    campaignDetailsLoading = false;
    campaignUpdatedVehicles = [];
    campaignEligibleVehicles = [];
    campaignWhitelistedVehicles = [];
    campaignFromGroupsFilter = [];
    campaignRollouts = [];
    shouldReload = 0;

    /**
     *
     * @type {StateMachineStore}
     */
    kpiState = {};

    /**
     *
     * @param {StateMachineStore} kpiState
     */
    constructor(kpiState) {
        makeObservable(this, {
            campaignLoading: observable,
            campaignList: observable,
            campaignListTotal: observable,
            campaignDetailsLoading: observable,
            campaignUpdatedVehicles: observable,
            campaignEligibleVehicles: observable,
            campaignWhitelistedVehicles: observable,
            campaignFromGroupsFilter: observable,
            campaignRollouts: observable,
            currentCampaign: observable,
            setCampaignLoading: action,
            setCampaignDetailsLoading: action,
            createCampaign: action,
            startCampaign: action,
            stopCampaign: action,
            deleteCampaign: action,
            getCampaigns: action,
            getCampaignsByRegion: action,
            getCampaignDetails: action,
        });
        this.kpiState = kpiState;
    }

    triggerReload() {
        // randomized value to trigger a useEffect in a component.
        this.shouldReload = uuidv4();
    }

    async getCampaignDetails(campaignId) {
        try {
            this.setCampaignDetailsLoading(true);
            const variables = {
                campaign_id: campaignId,
            };

            const res = await mapUpdateGraphqlClient.query({
                query: GET_CAMPAIGN_DETAILS,
                variables,
                fetchPolicy: 'no-cache',
            });
            const {
                campaigns,
                campaigns_eligible_vehicles,
                campaign_vehicles_from_vehicle_filter,
                campaign_vehicles_from_group_filter,
                campaigns_updated_vehicles,
                rollouts,
                campaign_vehicles_from_vehicle_filter_aggregate: {
                    aggregate: { count: total_whitelisted_vehicles },
                },
                campaign_vehicles_from_group_filter_aggregate: {
                    aggregate: { count: total_whitelisted_groups },
                },
                rolloutsCancelled_aggregate: {
                    aggregate: { count: rollouts_cancelled_count },
                },
                rolloutsFailed_aggregate: {
                    aggregate: { count: rollouts_failed_count },
                },
                rolloutsQueued_aggregate: {
                    aggregate: { count: rollouts_queued_count },
                },
                rolloutsStarted_aggregate: {
                    aggregate: { count: rollouts_started_count },
                },
                rolloutsSucceeded_aggregate: {
                    aggregate: { count: rollouts_succeeded_count },
                },
                rolloutsWarning_aggregate: {
                    aggregate: { count: rollouts_warning_count },
                },
                rollouts_aggregate: {
                    aggregate: { count: rollouts_count },
                },
            } = res.data.mu;
            this.currentCampaign = campaigns && campaigns.length > 0 ? campaigns[0] : null;
            this.currentCampaign.vehicles = campaigns_eligible_vehicles;
            this.currentCampaign.vehicles_whitelisted = campaign_vehicles_from_vehicle_filter;
            this.currentCampaign.group_name = campaign_vehicles_from_group_filter.length
                ? campaign_vehicles_from_group_filter[0].vehicle_group.group.name
                : null;
            this.currentCampaign.groups_whitelisted = campaign_vehicles_from_group_filter.map((data) => data.group);
            this.currentCampaign.updated_vehicles = campaigns_updated_vehicles;
            this.currentCampaign.total_groups_whitelisted = total_whitelisted_groups;
            this.currentCampaign.total_vehicles_whitelisted = total_whitelisted_vehicles;
            this.currentCampaign.rollouts = rollouts;
            this.currentCampaign.rollouts_count = rollouts_count;
            this.currentCampaign.rollouts_kpi = [
                {
                    name: campaignKpiStatus.STARTED,
                    count: rollouts_started_count,
                    color: 'primary',
                    clickUrl: `/rollouts?campaignId=${campaigns[0].id}&rolloutState=${campaignKpiStatus.STARTED}`,
                    animated: true,
                    test_id: 'campaign_details_rolloutsStarted',
                },
                {
                    name: campaignKpiStatus.QUEUED,
                    count: rollouts_queued_count,
                    color: 'secondary',
                    clickUrl: `/rollouts?campaignId=${campaigns[0].id}&rolloutState=${campaignKpiStatus.QUEUED}`,
                    test_id: 'campaign_details_rolloutsQueued',
                },
                {
                    name: campaignKpiStatus.SUCCEEDED,
                    count: rollouts_succeeded_count,
                    color: 'success',
                    clickUrl: `/rollouts?campaignId=${campaigns[0].id}&rolloutState=${campaignKpiStatus.SUCCEEDED}`,
                    test_id: 'campaign_details_rolloutsSucceeded',
                },
                {
                    name: campaignKpiStatus.WARNING,
                    count: rollouts_warning_count,
                    color: 'warning',
                    clickUrl: `/rollouts?campaignId=${campaigns[0].id}&rolloutState=${campaignKpiStatus.WARNING}`,
                    test_id: 'campaign_details_rolloutsWarning',
                },
                {
                    name: campaignKpiStatus.FAILED,
                    count: rollouts_failed_count,
                    color: 'danger',
                    clickUrl: `/rollouts?campaignId=${campaigns[0].id}&rolloutState=${campaignKpiStatus.FAILED}`,
                    test_id: 'campaign_details_rolloutsFailed',
                },
                {
                    name: campaignKpiStatus.CANCELLED,
                    count: rollouts_cancelled_count,
                    color: 'gray',
                    clickUrl: `/rollouts?campaignId=${campaigns[0].id}&rolloutState=${campaignKpiStatus.CANCELLED}`,
                    test_id: 'campaign_details_rolloutsCancelled',
                },
            ];
            return this.currentCampaign;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.campaigns.fetchCampaignDetails')}`);
            }
        } finally {
            this.setCampaignDetailsLoading(false);
        }
    }

    async getCampaigns(params = {}) {
        const { query, sortBy, sortDir } = params;
        try {
            const variables = {
                query: `%${query}%`,
                limit: 1000,
                offset: 0,
            };
            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }
            this.setCampaignLoading(true);
            const res = await mapUpdateGraphqlClient.query({
                query: SEARCH_CAMPAIGNS_QUERY,
                variables,
                fetchPolicy: 'no-cache',
            });
            const {
                campaigns,
                campaigns_aggregate: {
                    aggregate: { count: total },
                },
            } = res.data.mu;

            runInAction(() => {
                this.campaignList = campaigns;
                this.campaignListTotal = total;
            });

            return this.campaignList;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.campaigns.fetchCampaigns')}`);
            }
        } finally {
            this.setCampaignLoading(false);
        }
    }

    async getCampaignsByRegion(regions, params = {}) {
        const { query, sortBy, sortDir } = params;

        try {
            const variables = {
                query: `%${query}%`,
            };
            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }
            this.setCampaignLoading(true);
            const res = await mapUpdateGraphqlClient.query({
                query: buildSearchCampaignsByRegion(regions),
                variables,
                fetchPolicy: 'no-cache',
            });

            const result = res.data.mu;
            const resultEntries = Object.entries(result);
            this.campaignRegionListTotal = [];
            runInAction(() => {
                resultEntries
                    .filter((entry) => entry[0].endsWith('_aggregate'))
                    .map((entry) => {
                        const result = {
                            [entry[0].replace('_aggregate', '')]: entry[1].aggregate.count,
                        };
                        this.campaignRegionListTotal.push(result);
                        return result;
                    });
                this.campaignList = resultEntries
                    .filter((entry) => !entry[0].endsWith('_aggregate'))
                    .map((entry) => ({ [entry[0]]: entry[1] }));
            });
            return this.campaignList;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.campaigns.fetchCampaigns')}`);
            }
        } finally {
            this.setCampaignLoading(false);
        }
    }

    async getCampaignMetrics() {
        this.kpiState.setIsLoadingState();
        try {
            const res = await mapUpdateGraphqlClient.query({
                query: GET_CAMPAIGN_METRICS,
                fetchPolicy: 'no-cache',
            });
            const {
                campaignsCreated_aggregate: {
                    aggregate: { count: createdCampaigns },
                },
                campaignsStarted_aggregate: {
                    aggregate: { count: startedCampaigns },
                },
                campaignsStopped_aggregate: {
                    aggregate: { count: stoppedCampaigns },
                },
                rolloutsTotal_aggregate: {
                    aggregate: { count: totalRollouts },
                },
                rolloutsStarted_aggregate: {
                    aggregate: { count: startedRollouts },
                },
                rolloutsQueued_aggregate: {
                    aggregate: { count: queuedRollouts },
                },
                rolloutsSucceeded_aggregate: {
                    aggregate: { count: succeededRollouts },
                },
                rolloutsFailed_aggregate: {
                    aggregate: { count: failedRollouts },
                },
                rolloutsWarning_aggregate: {
                    aggregate: { count: warningRollouts },
                },
                rolloutsCancelled_aggregate: {
                    aggregate: { count: cancelledRollouts },
                },
            } = res.data.mu;

            return {
                campaignKPI: {
                    created: createdCampaigns,
                    started: startedCampaigns,
                    stopped: stoppedCampaigns,
                },
                rolloutsKPI: {
                    total: totalRollouts,
                    started: startedRollouts,
                    queued: queuedRollouts,
                    succeeded: succeededRollouts,
                    failed: failedRollouts,
                    warning: warningRollouts,
                    cancelled: cancelledRollouts,
                },
            };
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.campaigns.fetchCampaignMetrics')}`);
            }
        } finally {
            this.kpiState.setLoadedState();
        }
    }

    async getCampaignUpdatedVehicles() {
        try {
            const variables = {
                campaignId: `${this.currentCampaign.id}`,
            };
            this.setCampaignLoading(true);
            const result = await mapUpdateGraphqlClient.query({
                query: GET_CAMPAIGN_UPDATED_VEHICLES,
                variables,
                fetchPolicy: 'no-cache',
            });
            this.campaignUpdatedVehicles = result.data.mu.campaigns_updated_vehicles;
            return this.campaignUpdatedVehicles;
        } catch (error) {
            if (error.response) {
                Notification.error(`${error.response.statusText.toUpperCase()} - ${error}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.campaigns.fetchUpdatedCampaignVehicles')} (ID: ${
                        this.currentCampaign.id
                    })`
                );
            }
        } finally {
            this.setCampaignLoading(false);
        }
    }

    async getCampaignEligibleVehicles() {
        try {
            const variables = {
                campaignId: `${this.currentCampaign.id}`,
            };
            this.setCampaignLoading(true);
            const result = await mapUpdateGraphqlClient.query({
                query: GET_CAMPAIGN_ELIGIBLE_VEHICLES,
                variables,
                fetchPolicy: 'no-cache',
            });
            this.campaignEligibleVehicles = result.data.mu.campaigns_eligible_vehicles;
            return this.campaignEligibleVehicles;
        } catch (error) {
            if (error.response) {
                Notification.error(`${error.response.statusText.toUpperCase()} - ${error}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.campaigns.fetchEligibleCampaignVehicles')} (ID: ${
                        this.currentCampaign.id
                    })`
                );
            }
        } finally {
            this.setCampaignLoading(false);
        }
    }

    async getCampaignWhitelistedVehicles() {
        try {
            const variables = {
                campaignId: `${this.currentCampaign.id}`,
            };
            this.setCampaignLoading(true);
            const result = await mapUpdateGraphqlClient.query({
                query: GET_CAMPAIGN_WHITELISTED_VEHICLES,
                variables,
                fetchPolicy: 'no-cache',
            });
            this.campaignWhitelistedVehicles = result.data.mu.campaigns_whitelisted_vehicles;
            return this.campaignWhitelistedVehicles;
        } catch (error) {
            if (error.response) {
                Notification.error(`${error.response.statusText.toUpperCase()} - ${error}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.campaigns.fetchWhitelistedCampaignVehicles')} (ID: ${
                        this.currentCampaign.id
                    })`
                );
            }
        } finally {
            this.setCampaignLoading(false);
        }
    }

    async getCampaignFromGroupsFilter() {
        try {
            const variables = {
                campaignId: `${this.currentCampaign.id}`,
            };
            this.setCampaignLoading(true);
            const result = await mapUpdateGraphqlClient.query({
                query: GET_CAMPAIGN_FROM_GROUPS_FILTER,
                variables,
                fetchPolicy: 'no-cache',
            });
            this.campaignFromGroupsFilter = result.data.mu.campaign_vehicles_from_group_filter;
            return this.campaignFromGroupsFilter;
        } catch (error) {
            if (error.response) {
                Notification.error(`${error.response.statusText.toUpperCase()} - ${error}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.campaigns.fetchCampaignGroups')} (ID: ${
                        this.currentCampaign.id
                    })`
                );
            }
        } finally {
            this.setCampaignLoading(false);
        }
    }

    async getCampaignRollouts() {
        try {
            const variables = {
                campaignId: `${this.currentCampaign.id}`,
            };
            this.setCampaignLoading(true);
            const result = await mapUpdateGraphqlClient.query({
                query: GET_CAMPAIGN_ROLLOUTS,
                variables,
                fetchPolicy: 'no-cache',
            });
            this.campaignRollouts = result.data.mu.rollouts;
            return this.campaignRollouts;
        } catch (error) {
            if (error.response) {
                Notification.error(`${error.response.statusText.toUpperCase()} - ${error}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.campaigns.fetchCampaignRollouts')} (ID: ${
                        this.currentCampaign.id
                    })`
                );
            }
        } finally {
            this.setCampaignLoading(false);
        }
    }

    async createCampaign(data) {
        const payloadCreateCampaign = {
            mapId: data.selectedMap.id,
        };

        if (data.customName !== undefined && data.customName !== '' && data.customName != null) {
            payloadCreateCampaign.customName = data.customName;
        }

        if (data.selectedGroupIds.length > 0 || data.selectedGroupIds > 0) {
            payloadCreateCampaign.filters = {
                filterBy: {
                    // for now we are ensuring that one filter has values (the other one is empty so we can just transfer both fields in the DTO/request payload)
                    vehicleIds: data.selectedVehicleIds,
                    groupIds: data.selectedGroupIds,
                },
            };
        }

        try {
            const res = await mapUpdateRestApiClient.createCampaign(null, payloadCreateCampaign);
            const createdCampaignId = res.data.mu?.campaignId;
            Notification.success(
                `${i18n.t('fotaone.notification.success.campaigns.createCampaign')} (ID: ${createdCampaignId})`
            );
            this.triggerReload();
            await this.getCampaigns();
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.campaigns.createCampaign')}`);
            }
        }
    }

    async startCampaign() {
        const payloadStartCampaign = {
            campaignId: this.currentCampaign.id,
            retriableRollouts: true,
        };
        try {
            await mapUpdateRestApiClient.startCampaign(null, payloadStartCampaign);
            Notification.success(
                `${i18n.t('fotaone.notification.success.campaigns.startCampaign')} (ID: ${
                    payloadStartCampaign.campaignId
                })`
            );
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.campaigns.startCampaign')} (ID: ${
                        payloadStartCampaign.campaignId
                    }).`
                );
            }
        } finally {
            this.getCampaignDetails(payloadStartCampaign.campaignId);
            this.triggerReload();
            await this.getCampaigns();
        }
    }

    async stopCampaign() {
        const payloadStopCampaign = {
            campaignId: this.currentCampaign.id,
        };
        try {
            await mapUpdateRestApiClient.stopCampaign(null, payloadStopCampaign);
            Notification.success(
                `${i18n.t('fotaone.notification.success.campaigns.stoppedCampaign')} (ID: ${
                    payloadStopCampaign.campaignId
                })`
            );
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.campaigns.stoppedCampaign')} (ID: ${
                        payloadStopCampaign.campaignId
                    }).`
                );
            }
        } finally {
            this.getCampaignDetails(payloadStopCampaign.campaignId);
            this.triggerReload();
            await this.getCampaigns();
        }
    }

    async pauseCampaign() {
        try {
            const payloadPauseCampaign = {
                campaignId: this.currentCampaign.id,
            };
            await mapUpdateRestApiClient.pauseCampaign(null, payloadPauseCampaign);
            Notification.success(
                `${i18n.t('fotaone.notification.success.campaigns.pausedCampaign')} (ID: ${
                    payloadPauseCampaign.campaignId
                })`
            );
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.campaigns.pausedCampaign')} (ID: ${this.currentCampaign.id}).`
                );
            }
        } finally {
            this.getCampaignDetails(this.currentCampaign.id);
            this.triggerReload();
            await this.getCampaigns();
        }
    }

    async resumeCampaign() {
        try {
            const payloadResumeCampaign = {
                campaignId: this.currentCampaign.id,
            };
            await mapUpdateRestApiClient.resumeCampaign(null, payloadResumeCampaign);
            Notification.success(
                `${i18n.t('fotaone.notification.success.campaigns.resumedCampaign')} (ID: ${
                    payloadResumeCampaign.campaignId
                })`
            );
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.campaigns.resumedCampaign')} (ID: ${this.currentCampaign.id})`
                );
            }
        } finally {
            this.getCampaignDetails(this.currentCampaign.id);
            this.triggerReload();
            await this.getCampaigns();
        }
    }

    async deleteCampaign() {
        const campaignId = this.currentCampaign.id;
        try {
            await mapUpdateRestApiClient.deleteCampaign(campaignId);
            Notification.success(
                `${i18n.t('fotaone.notification.success.campaigns.deletedCampaign')} (ID: ${campaignId})`
            );
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.campaigns.deletedCampaign')} (ID: ${campaignId})`
                );
            }
        } finally {
            this.triggerReload();
        }
    }

    setCampaignLoading(state) {
        this.campaignLoading = Boolean(state);
    }

    setCampaignDetailsLoading(state) {
        this.campaignDetailsLoading = Boolean(state);
    }

    static instance(kpiState) {
        if (!instance) {
            instance = new CampaignStore(kpiState);
        }
        return instance;
    }
}
