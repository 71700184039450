import React from 'react';
import { get } from 'lodash';
import { statusColorCode } from '../../mapupdate/mapupdateHelper/constants.jsx';
import { useTranslation } from 'react-i18next';

function TableBody(props) {
    const { t } = useTranslation();
    const { columns, actionItemRenderer, hiddenColumns, rows, actionColumn, onRowClick } = props;
    const filteredColumns = columns.filter((col) => !hiddenColumns.includes(col.key));

    const handleRowClick = (...params) => {
        const { row } = params;
        if (row?.disabled) return;

        if (onRowClick) {
            onRowClick(...params);
        }
    };

    const setStatusColor = (status) => {
        return statusColorCode[status] || statusColorCode['UNKNOWN'];
    };

    const isHighlightedRow = (selectedRow, currentRow) => {
        return selectedRow && selectedRow === get(currentRow, props.rowKey) ? 'info' : '';
    };

    return (
        <tbody>
            {rows
                .filter((r) => r)
                .map((row) => {
                    return (
                        <React.Fragment key={row.id}>
                            {row.disabled == null ? false : row.disabled}
                            <tr
                                className={`${row.disabled ? 'disabled' : ''}${isHighlightedRow(
                                    props?.selectedRow,
                                    row,
                                )}`}
                                onClick={(e) => handleRowClick(row, e)}
                            >
                                {filteredColumns.map((col) => {
                                    const columnData = col.renderer
                                        ? col.renderer(get(row, col.key), row, col.key)
                                        : get(row, col.key);
                                    return (
                                        <td
                                            // check if it is status, add colorCoding
                                            className={col.statusColor ? setStatusColor(columnData) : ''}
                                            key={col.key}
                                            data-field={col.title}
                                        >
                                            <span>
                                                {columnData?.props
                                                ? columnData
                                                : `${t(columnData)}`
                                                }
                                            </span>
                                            {col.badge?.(get(row, col.key), row)}
                                        </td>
                                    );
                                })}
                                {actionItemRenderer && <td className={actionColumn}>{actionItemRenderer(row)}</td>}
                            </tr>
                        </React.Fragment>
                    );
                })}
        </tbody>
    );
}

export default TableBody;
