import { action, makeObservable, observable } from 'mobx';
import { gql } from '@apollo/client';
import { client as featureDashboardRestApiClient } from '@api/featuredashboard-client';
import { client as featureDashboardGraphqlClient } from '@api/featuredashboard-graphql';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { setWith } from 'lodash';
import i18n from 'i18next';

let instance; // singleton instance

const GET_MESSAGE_TEMPLATES = gql`
    query GetMessageTemplates {
        fd {
            message_templates {
                content
                id
                language_code
                severity
                title
                type
            }
        }
    }
`;

const GET_MESSAGES = gql`
    query GetMessages($limit: Int, $offset: Int = 0, $query: String = "%", $orderBy: [fd_messages_order_by!] = {}) {
        fd {
            messages(
                limit: $limit
                offset: $offset
                order_by: $orderBy
                where: {
                    _and: [
                        {
                            _or: [
                                { device_messages: { device: { vin: { _ilike: $query } } } }
                                { title: { _ilike: $query } }
                            ]
                        }
                        {
                            _or: [
                                { device_messages: { status: { _eq: "SENT" } } }
                                { device_messages: { status: { _eq: "SENDING_FAILED" } } }
                            ]
                        }
                    ]
                }
            ) {
                id
                severity
                status
                title
                type
                device_messages {
                    device {
                        vin
                    }
                    status
                }
                device_message_events(where: { operation: { _eq: "APPROVED" } }) {
                    created_at
                }
            }
            messages_aggregate(
                where: {
                    _and: [
                        {
                            _or: [
                                { device_messages: { device: { vin: { _ilike: $query } } } }
                                { title: { _ilike: $query } }
                            ]
                        }
                        {
                            _or: [
                                { device_messages: { status: { _eq: "SENT" } } }
                                { device_messages: { status: { _eq: "SENDING_FAILED" } } }
                            ]
                        }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const GET_MESSAGE_DETAILS = gql`
    query GetMessageDetailsQuery($message_id: uuid!) {
        fd {
            messages(where: { id: { _eq: $message_id } }) {
                id
                content
                type
                title
                status
                severity
                language_code
                kba_number
                device_message_events {
                    created_at
                    created_by
                    entity_type
                    operation
                    id
                }
                device_messages {
                    device_id
                    message_id
                    device {
                        vin
                        serial_number
                        type
                    }
                    status
                }
            }
        }
    }
`;

const GET_MY_MESSAGES = gql`
    query GetMyMessages(
        $limit: Int
        $offset: Int = 0
        $query: String = "%"
        $orderBy: [fd_messages_order_by!] = {}
        $createdBy: String = "%"
    ) {
        fd {
            messages(
                limit: $limit
                offset: $offset
                order_by: $orderBy
                where: {
                    _and: [
                        {
                            _or: [
                                { device_messages: { device: { vin: { _ilike: $query } } } }
                                { title: { _ilike: $query } }
                            ]
                        }
                        { device_message_events: { created_by: { _eq: $createdBy } } }
                        { device_message_events: { entity_type: { _eq: "MESSAGE" } } }
                    ]
                }
            ) {
                id
                severity
                status
                title
                content
                type
                kba_number
                language_code
                device_messages {
                    device {
                        vin
                    }
                    status
                }
                device_message_events {
                    created_at
                }
            }
            messages_aggregate(
                where: {
                    _and: [
                        {
                            _or: [
                                { device_messages: { device: { vin: { _ilike: $query } } } }
                                { title: { _ilike: $query } }
                            ]
                        }
                        { device_message_events: { created_by: { _eq: $createdBy } } }
                        { device_message_events: { entity_type: { _eq: "MESSAGE" } } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const GET_MESSAGES_TO_APPROVE = gql`
    query GetMessagesToApprove(
        $limit: Int
        $offset: Int = 0
        $query: String = "%"
        $orderBy: [fd_messages_order_by!] = {}
    ) {
        fd {
            messages(
                limit: $limit
                offset: $offset
                order_by: $orderBy
                where: {
                    _and: [
                        {
                            _or: [
                                { device_messages: { device: { vin: { _ilike: $query } } } }
                                { title: { _ilike: $query } }
                            ]
                        }
                        { _or: [{ status: { _eq: "PENDING_APPROVAL" } }] }
                    ]
                }
            ) {
                id
                severity
                status
                title
                type
                device_messages {
                    device {
                        vin
                    }
                    status
                }
                device_message_events {
                    created_at
                    created_by
                    entity_type
                }
            }
            messages_aggregate(
                where: {
                    _and: [
                        {
                            _or: [
                                { device_messages: { device: { vin: { _ilike: $query } } } }
                                { title: { _ilike: $query } }
                            ]
                        }
                        { _or: [{ status: { _eq: "PENDING_APPROVAL" } }] }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const GET_RM_VEHICLES = gql`
    query searchVehicles(
        $limit: Int
        $offset: Int = 0
        $query: String = "%"
        $orderBy: [fd_devices_order_by!] = {}
        $filter: [fd_devices_bool_exp!] = {}
    ) {
        fd {
            devices(
                limit: $limit
                offset: $offset
                order_by: $orderBy
                where: {
                    _and: [
                        { vin: { _is_null: false } }
                        { assets: { id: { _is_null: false } } }
                        {
                            _or: [
                                { vin: { _ilike: $query } }
                                { assets: { name: { _ilike: $query } } }
                                { serial_number: { _ilike: $query } }
                            ]
                            _and: $filter
                        }
                    ]
                    assets: { activations_aggregate: { count: { predicate: { _gt: 0 } } } }
                }
            ) {
                id
                vin
                serial_number
                type
                assets {
                    id
                    name
                    account_id
                }
                device_messages_aggregate{
                    aggregate {
                        count
                    }
                }
            }
            devices_aggregate(
                where: {
                    _and: [
                        { vin: { _is_null: false } }
                        { assets: { id: { _is_null: false } } }
                        {
                            _or: [
                                { vin: { _ilike: $query } }
                                { assets: { name: { _ilike: $query } } }
                                { serial_number: { _ilike: $query } }
                            ]
                            _and: $filter
                        }
                    ]
                    assets: { activations_aggregate: { count: { predicate: { _gt: 0 } } } }
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const GET_VEHICLE_DETAILS = gql`
    query GetVehicleDetailsQuery($device_id: uuid!) {
        fd {
            devices(where: {id: {_eq: $device_id}}) {
                id
                serial_number
                type
                vin
                assets {
                    name
                    id
                }
                device_messages {
                    status
                    message_id
                    message {
                        type
                        title
                        status
                        device_message_events(order_by: {created_at: desc}) {
                            created_at
                            operation
                        }
                    }
                }
                device_messages_aggregate {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`;

export class RemoteMessageStore {
    // ---- Template
    messageTemplatesList = [];
    messageTemplatesListTotal = 0;
    messageTemplatesLoading = false;
    messageTemplateDialogOpen = false;
    editMessageTemplate = null;

    // ---- Message LIST
    messagesList = [];
    messagesListTotal = 0;
    messagesListLoading = false;

    myMessagesList = [];
    myMessagesListTotal = 0;
    myMessagesListLoading = false;

    messagesApprovalList = [];
    messagesApprovalListTotal = 0;
    messagesApprovalListLoading = false;
    // ---- Message
    messageDetailsLoading = false;
    messageDetails = {};
    messageDialogOpen = false;
    editMyMessage = null;
    handleMessageDecision = null;
    editMyMessageLoading = false;
    handleMessageDecisionLoading = false;
    createMyMessageLoading = false;
    deleteMyMessageLoading = false;
    approveMessageLoading = false;
    rejectMessageLoading = false;

    // --- Vehicles
    vehiclesLoading = false;
    vehicleDetailsLoading = false;
    vehicleDetails = {};

    // ----- Feedback
    sendFeedbackLoading= false;

    constructor() {
        makeObservable(this, {
            // ---- Template
            messageTemplatesList: observable,
            messageTemplatesListTotal: observable,
            messageTemplatesLoading: observable,
            messageTemplateDialogOpen: observable,
            editMessageTemplate: observable,

            // ---- Message LIST
            messagesList: observable,
            messagesListTotal: observable,
            messagesListLoading: observable,

            myMessagesList: observable,
            myMessagesListTotal: observable,
            myMessagesListLoading: observable,

            messagesApprovalList: observable,
            messagesApprovalListTotal: observable,
            messagesApprovalListLoading: observable,
            // ---- Message
            messageDetails: observable,
            messageDetailsLoading: observable,
            messageDialogOpen: observable,
            editMyMessage: observable,
            handleMessageDecision: observable,
            editMyMessageLoading: observable,
            handleMessageDecisionLoading: observable,
            createMyMessageLoading: observable,
            deleteMyMessageLoading: observable,
            approveMessageLoading: observable,
            rejectMessageLoading: observable,

            // ---- Vehicle
            vehiclesLoading: observable,
            vehicleDetailsLoading: observable,
            vehicleDetails: observable,

            // ---- Actions
            getMessageDetails: action,
            getVehicleDetails: action,
            setEditMyMessage: action,
            setHandleMessageDecision: action,

            // ----- Feedback
            sendFeedbackLoading: observable,
        });
    }

    async getMessageTemplates() {
        try {
            this.setMessageTemplatesLoading(true);
            const res = await featureDashboardGraphqlClient.query({
                query: GET_MESSAGE_TEMPLATES,
                fetchPolicy: 'no-cache',
            });

            const { message_templates } = res.data.fd;
            this.messageTemplatesList = message_templates;
            return this.messageTemplatesList;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.fetchMessageTemplates')}`);
            }
        } finally {
            this.setMessageTemplatesLoading(false);
        }
    }

    async getMessagesList(params = {}) {
        const { query, sortBy, sortDir, limit, offset } = params;
        try {
            this.setMessagesListLoading(true);
            const variables = {
                query: `%${query === undefined ? '' : query}%`,
                limit,
                offset,
            };

            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }

            const res = await featureDashboardGraphqlClient.query({
                query: GET_MESSAGES,
                variables,
                fetchPolicy: 'no-cache',
            });
            const { messages, messages_aggregate } = res.data.fd;
            this.messagesList = messages;
            this.messagesListTotal = messages_aggregate.aggregate.count;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.fetchMessages')}`);
            }
        } finally {
            this.setMessagesListLoading(false);
        }
    }

    async getMessageDetails(messageId) {
        try {
            this.setMessageDetailsLoading(true);
            const variables = {
                message_id: messageId,
            };
            const res = await featureDashboardGraphqlClient.query({
                query: GET_MESSAGE_DETAILS,
                variables,
                fetchPolicy: 'no-cache',
            });
            this.messageDetails = res.data.fd.messages[0];
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.statusText.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.fetchMessageDetails')}`);
            }
        } finally {
            this.setMessageDetailsLoading(false);
        }
    }

    async getMyMessagesList(params = {}) {
        const { query, sortBy, sortDir, limit, offset, profileEmail } = params;
        try {
            this.setMyMessagesListLoading(true);
            const variables = {
                query: `%${query === undefined ? '' : query}%`,
                limit,
                offset,
                createdBy: profileEmail,
            };

            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }

            const res = await featureDashboardGraphqlClient.query({
                query: GET_MY_MESSAGES,
                variables,
                fetchPolicy: 'no-cache',
            });

            const { messages, messages_aggregate } = res.data.fd;
            this.myMessagesList = messages;
            this.myMessagesListTotal = messages_aggregate.aggregate.count;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.fetchMessages')}`);
            }
        } finally {
            this.setMyMessagesListLoading(false);
        }
    }

    async getMessagesToApprove(params = {}) {
        const { query, sortBy, sortDir, limit, offset } = params;
        try {
            this.setMessagesApprovalListLoading(true);
            const variables = {
                query: `%${query === undefined ? '' : query}%`,
                limit,
                offset,
            };

            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }

            const res = await featureDashboardGraphqlClient.query({
                query: GET_MESSAGES_TO_APPROVE,
                variables,
                fetchPolicy: 'no-cache',
            });
            const { messages, messages_aggregate } = res.data.fd;
            this.messagesApprovalList = messages;
            this.messagesApprovalListTotal = messages_aggregate.aggregate.count;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.fetchMessages')}`);
            }
        } finally {
            this.setMessagesApprovalListLoading(false);
        }
    }

    async getVehicles(searchParams = {}) {
        this.setVehiclesLoading(true);
        const { query, sortBy, sortDir, limit, offset } = searchParams;
        try {
            const variables = {
                query: `%${query === undefined ? '' : query}%`,
                limit,
                offset,
                filter: {},
            };
            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }
            const res = await featureDashboardGraphqlClient.query({
                query: GET_RM_VEHICLES,
                variables,
                fetchPolicy: 'no-cache',
            });
            const {
                devices,
                devices_aggregate: {
                    aggregate: { count: total },
                },
            } = res.data.fd;
            this.vehicleList = devices;
            this.vehicleTotal = total;
            return this.vehicleList;
        } catch (err) {
            Notification.error(`${i18n.t('fotaone.notification.error.assets.fetchVehicles')}`);
        } finally {
            this.setVehiclesLoading(false);
        }
    }

    async getVehicleDetails(deviceId) {
        try {
            this.setVehicleDetailsLoading(true);
            const variables = {
                device_id: deviceId,
            };
            const res = await featureDashboardGraphqlClient.query({
                query: GET_VEHICLE_DETAILS,
                variables,
                fetchPolicy: 'no-cache',
            });
            this.vehicleDetails = res.data.fd.devices[0];
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response?.statusText?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.assets.fetchVehicleDetails')}`);
            }
        } finally {
            this.setVehicleDetailsLoading(false);
        }
    }

    async createMessageTemplate(data) {
        const { title, type, severity, language_code, content } = data;
        const payloadCreateMessageTemplate = {
            title,
            content,
            severity,
            type,
            language_code,
        };
        try {
            this.setMessageTemplatesLoading(true);
            const res = await featureDashboardRestApiClient.createTemplate(null, payloadCreateMessageTemplate);
            this.getMessageTemplates();
            Notification.success(
                `${i18n.t('fotaone.notification.success.messages.createMessageTemplate')} - ${
                    payloadCreateMessageTemplate.title
                }`
            );
            return res;
        } catch (err) {
            if (err.respone) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.createMessageTemplate')}`);
            }
        } finally {
            this.setMessageTemplatesLoading(false);
        }
    }

    async updateMessageTemplate(data) {
        const { id, title, type, severity, language_code, content } = data;
        const payloadUpdateMessageTemplate = {
            title,
            content,
            severity,
            type,
            language_code,
        };
        try {
            this.setMessageTemplatesLoading(true);
            const res = await featureDashboardRestApiClient.updateTemplate(id, payloadUpdateMessageTemplate);
            this.getMessageTemplates();
            Notification.success(
                `${i18n.t('fotaone.notification.success.messages.updateMessageTemplate')} - ${
                    payloadUpdateMessageTemplate.title
                }`
            );
            return res;
        } catch (err) {
            if (err.respone) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.updateMessageTemplate')}`);
            }
        } finally {
            this.setMessageTemplatesLoading(false);
        }
    }

    async deleteMessageTemplate(id) {
        try {
            this.setMessageTemplatesLoading(true);
            const res = await featureDashboardRestApiClient.deleteTemplate(id);
            this.getMessageTemplates();
            Notification.success(`${i18n.t('fotaone.notification.success.messages.deleteMessageTemplate')}`);
            return res;
        } catch (err) {
            if (err.respone) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.deleteMessageTemplate')}`);
            }
        } finally {
            this.setMessageTemplatesLoading(false);
        }
    }

    async createMessage(data) {
        const { title, content, severity, type, kba_number, language_code, devices } = data;
        const payloadCreateMessage = {
            title,
            content,
            severity,
            type,
            kba_number,
            language_code,
            devices,
        };
        try {
            this.setCreateMyMessageLoading(true);
            const res = await featureDashboardRestApiClient.createMessage(null, payloadCreateMessage);
            Notification.success(
                `${i18n.t('fotaone.notification.success.messages.createMessage')} - ${payloadCreateMessage.title}`
            );
            return res;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.createMessage')}`);
            }
        } finally {
            this.setCreateMyMessageLoading(false);
        }
    }

    async updateMessage(data) {
        const { id, title, content, severity, type, kba_number, language_code, devices } = data;
        const payloadUpdateMessage = {
            title,
            content,
            severity,
            type,
            kba_number,
            language_code,
            devices,
        };
        try {
            this.setEditMyMessageLoading(true);
            const res = await featureDashboardRestApiClient.updateMessage(id, payloadUpdateMessage);
            Notification.success(
                `${i18n.t('fotaone.notification.success.messages.updateMessage')} - ${payloadUpdateMessage.title}`
            );
            await this.getMyMessagesList({ query, sortBy, sortDir, limit, offset, profileEmail });
            return res;
        } catch (err) {
            if (err.respone) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.updateMessage')}`);
            }
        } finally {
            this.setEditMyMessageLoading(false);
        }
    }

    async deleteMyMessage(data) {
        const { messageId, query, sortBy, sortDir, limit, offset, profileEmail } = data;
        try {
            this.setDeleteMyMessageLoading(true);
            const res = await featureDashboardRestApiClient.deleteMessage(messageId);
            await this.getMyMessagesList({ query, sortBy, sortDir, limit, offset, profileEmail });
            Notification.success(`${i18n.t('fotaone.notification.success.messages.deleteMessage')}`);
            return res;
        } catch (err) {
            if (err.respone) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.deleteMessage')}`);
            }
        } finally {
            this.setDeleteMyMessageLoading(false);
        }
    }

    async requestApproval(data) {
        const { messageId, query, sortBy, sortDir, limit, offset, profileEmail } = data;
        try {
            const res = await featureDashboardRestApiClient.requestMessageApproval(messageId);
            await this.getMyMessagesList({ query, sortBy, sortDir, limit, offset, profileEmail });
            Notification.success('The approval request was sent successfully.');
            return res;
        } catch (err) {
            if (err.respone) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error('The approval request could not be sent.');
            }
        }
    }

    async cancelRequestApproval(params) {
        const { messageId, query, sortBy, sortDir, limit, offset, profileEmail } = params;
        try {
            const res = await featureDashboardRestApiClient.cancelMessageApproval(messageId);
            await this.getMyMessagesList({ query, sortBy, sortDir, limit, offset, profileEmail });
            Notification.success('The cancel approval request was sent successfully.');
            return res;
        } catch (err) {
            if (err.respone) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error('The cancel approval request could not be sent.');
            }
        }
    }

    async approveMessage(data) {
        const { messageId, query, sortBy, sortDir, limit, offset } = data;
        try {
            this.setApproveMessageLoading(true);
            this.setHandleMessageDecisionLoading(true);
            const res = await featureDashboardRestApiClient.approveMessage(messageId);
            await this.getMessagesToApprove(query, sortBy, sortDir, limit, offset);
            Notification.success(`${i18n.t('fotaone.notification.success.messages.approveMessage')}`);
            return res;
        } catch (err) {
            if (err.respone) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.approveMessage')}`);
            }
        } finally {
            this.setApproveMessageLoading(false);
            this.setHandleMessageDecisionLoading(false);
        }
    }

    async rejectMessage(data) {
        const { messageId, rejectReason, query, sortBy, sortDir, limit, offset } = data;
        const rejectionReason = {
            reason: rejectReason
        };
        try {
            this.setRejectMessageLoading(true);
            this.setHandleMessageDecisionLoading(true);
            const res = await featureDashboardRestApiClient.rejectMessage(messageId, rejectionReason);
            await this.getMessagesToApprove(query, sortBy, sortDir, limit, offset);
            Notification.success(`${i18n.t('fotaone.notification.success.messages.rejectMessage')}`);
            return res;
        } catch (err) {
            if (err.respone) {
                Notification.error(`${err.response?.data?.message?.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.messages.rejectMessage')}`);
            }
        } finally {
            this.setRejectMessageLoading(false);
            this.setHandleMessageDecisionLoading(false);
        }
    }

    async sendFeedback(data) {
        const { rating, details, isToContact } = data;
        const payloadSendFeedback = {
            rating: parseInt(rating, 10),
            details,
            isToContact
        };
        try {
            this.setSendFeedbackLoading(true);
            return await featureDashboardRestApiClient.saveUserFeedback(null, payloadSendFeedback);
        } catch (error) {
            this.setSendFeedbackLoading(false);
            if (error.response) {
                Notification.error(`${error.response.data.message.toUpperCase()} - ${error}`);
            } else {
                Notification.error('Error sending feedback');
            }
        } finally {
            this.setSendFeedbackLoading(false);
        }
    }

    setVehiclesLoading(state) {
        this.vehiclesLoading = Boolean(state);
    }

    setVehicleDetailsLoading(state) {
        this.vehicleDetailsLoading = Boolean(state);
    }

    setMessageTemplatesLoading(state) {
        this.messageTemplatesLoading = Boolean(state);
    }

    setMessageTemplateDialogOpen(state) {
        this.messageTemplateDialogOpen = Boolean(state);
    }

    setEditMessageTemplate(template) {
        this.editMessageTemplate = template;
    }

    setMessagesListLoading(state) {
        this.messagesListLoading = Boolean(state);
    }

    setMessageDetailsLoading(state) {
        this.messageDetailsLoading = Boolean(state);
    }

    setMyMessagesListLoading(state) {
        this.myMessagesListLoading = Boolean(state);
    }
    setMessagesApprovalListLoading(state) {
        this.messagesApprovalListLoading = Boolean(state);
    }

    setMessageDialogOpen(state) {
        this.messageDialogOpen = Boolean(state);
    }

    setEditMyMessage(message) {
        this.editMyMessage = message;
    }

    setHandleMessageDecision(message) {
        this.handleMessageDecision = message;
    }

    setHandleMessageDecisionLoading(state) {
        this.handleMessageDecisionLoading = Boolean(state);
    }

    setCreateMyMessageLoading(state) {
        this.createMyMessageLoading = Boolean(state);
    }

    setEditMyMessageLoading(state) {
        this.editMyMessageLoading = Boolean(state);
    }

    setDeleteMyMessageLoading(state) {
        this.deleteMyMessageLoading = Boolean(state);
    }

    setApproveMessageLoading(state) {
        this.approveMessageLoading = Boolean(state);
    }

    setRejectMessageLoading(state) {
        this.rejectMessageLoading = Boolean(state);
    }

    setSendFeedbackLoading(state) {
        this.sendFeedbackLoading = Boolean(state);
    }

    static instance() {
        if (!instance) {
            instance = new RemoteMessageStore();
        }
        return instance;
    }
}
